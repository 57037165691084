import * as React from 'react';

import { Head } from '@/components/head';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const Layout = ({ children, title }: LayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="divide-y-2 px-2">
        <div className="py-2 text-center">
          <h1 className="text-2xl font-bold">{title}</h1>
        </div>
        <div>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};
