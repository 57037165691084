import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';
import styles from './RangeField.module.css';

/* TODO: 눈금 표시 뭔가 안됨 */

export type RangeFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  min: number;
  max: number;
  unit: string;
  defaultVal: number;
  listId: string;
  step: number;
  rangeList: number[];
};

export const RangeField = (props: RangeFieldProps): ReactElement => {
  const {
    label,
    error,
    registration,
    className,
    unit = '시간',
    min = 0,
    max = 50,
    step = 1,
    rangeList = [],
    listId = '',
    defaultVal = 0,
  } = props;

  const [rangeVal, setRangeVal] = React.useState<number>(defaultVal);

  const changeHandler = (e: any) => {
    setRangeVal(e.target.value);
  };

  return (
    <FieldWrapper label={label} error={error} className="">
      <div className="flex-col text-lg">
        <div className="text-center">
          {rangeVal}
          {unit}
        </div>
        <div className={clsx(styles.range, 'flex w-60 items-center gap-x-2')}>
          <span>{min}</span>
          <input
            type="range"
            min={min}
            max={max}
            step={step}
            list={listId}
            defaultValue={rangeVal}
            onInput={changeHandler}
            onTouchMove={changeHandler}
            onTouchEnd={changeHandler}
            className={clsx(className)}
            {...registration}
          />
          <datalist id={listId}>
            {rangeList.map((value) => (
              <option key={value} value={value}></option>
            ))}
          </datalist>
          <span>{max}</span>
        </div>
      </div>
    </FieldWrapper>
  );
};
