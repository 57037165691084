// import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// import { Spinner } from '@/components/elements/spinner';
import { MainLayout } from '@/components/layout/MainLayout';
import { Landing } from '@/features/misc/routes/Landing';
import { useAuth } from '@/lib/auth';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const auth = useAuth();
  const commonRoutes = [
    {
      path: '/',
      element: (
        <MainLayout>
          <Landing />
        </MainLayout>
      ),
    },
  ];
  const routes = auth.user ? protectedRoutes : publicRoutes;
  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
