import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

export type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password' | 'range';
  placeholder?: string;
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const InputField = (props: InputFieldProps): ReactElement => {
  const {
    type = 'text',
    label,
    error,
    registration,
    placeholder,
    className,
  } = props;

  return (
    <FieldWrapper label={label} error={error}>
      <input
        placeholder={placeholder}
        type={type}
        className={clsx(
          `${
            error?.message
              ? 'border-caution placeholder-caution'
              : 'border-black placeholder-gray-400'
          } block w-full appearance-none rounded border px-3  py-2 align-middle font-sans focus:border-blue-500 focus:outline-none focus:ring-blue-500`,
          className
        )}
        {...registration}
      />
    </FieldWrapper>
  );
};
