import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

// import { Spinner } from '@/components/elements/spinner';
import { Notifications } from '@/components/notifications';
import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/react-query';

export const ErrorFallback = () => {
  return (
    <div
      className="flex h-screen w-screen flex-col items-center justify-center text-center"
      role="alert"
    >
      <div className="text-xl text-blue-400">
        <strong>죄송합니다.</strong>
      </div>
      <div className="text-lg">
        <p>
          서비스 이용에 불편을 드려 죄송합니다. <br />
          <strong>에러가</strong> 발생하여 페이지를 표시할 수 없습니다. <br />
          관리자에게 문의하시거나 잠시 후 다시 시도하세요.
        </p>
      </div>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center">
          {/* <Spinner size="xl" /> */}
          THE WHITEOCEAN 진입중...
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <Notifications />
            <AuthProvider>
              <Router>{children}</Router>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
