import clsx from 'clsx';

import { colorName, ColorSet } from '@/styles/colors';
import { iconName, IconSet } from '@/styles/constants';

interface IconProps {
  icon: iconName;
  size: number;
  color: colorName;
  className?: string;
}

export const Icon = ({ icon, size, color, className }: IconProps) => {
  return (
    <svg
      height={size}
      viewBox={IconSet[icon].viewBox}
      fill={ColorSet[color].c100}
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className)}
    >
      <path d={IconSet[icon].path} />
    </svg>
  );
};
