import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import woprofile from '@/assets/images/placeprofile.png';
import wointerview from '@/assets/images/preinterview.png';
import wologo from '@/assets/images/wologo.png';
import womapsearch from '@/assets/images/womapsearch.png';
import { HospitalsSearchBar } from '@/features/map/components/HospitalsSearchBar';
import { useAuth } from '@/lib/auth';
import { useChosenStore } from '@/stores/hospital';
import { filterUserStatus } from '@/utils/user-status';

export const Landing = () => {
  const { chosenHospital } = useChosenStore((state) => state);
  const auth = useAuth();

  const navigate = useNavigate();

  React.useEffect(() => {
    auth.refetchUser();
    console.log(auth.user);
  }, [auth]);

  React.useEffect(() => {
    if (chosenHospital.id > 0) {
      const status = filterUserStatus(auth.user);
      switch (status) {
        case 'unAuthenticated':
          navigate('/auth/login');
          break;

        case 'unIdentified':
          navigate('/app/chat');
          break;

        case 'inActive':
          navigate('/app/review');
          break;

        default:
          navigate('/app/map');
          break;
      }
    }
  }, [chosenHospital.id, navigate, auth.user]);

  return (
    <>
      <div className="p-4">
        <div className="flex flex-col items-center gap-y-10 py-10">
          <div className="flex items-center justify-center">
            <img src={wologo} className="mx-auto h-16 w-16" alt="whiteocean" />
          </div>
          <div className="text-center">
            <span className="text-4xl font-extrabold">화이트오션</span>
          </div>
          <div className="flex flex-col text-center text-2xl font-bold">
            <div>내일 면접 보려는 그 병원,</div>
            <div>어떻게 일하는지 궁금해?</div>
          </div>
          <HospitalsSearchBar />
        </div>
        <div>
          <img src={womapsearch} alt="" className="mx-auto h-full w-full" />
        </div>
        <div>
          <img src={wointerview} alt="" className="mx-auto h-full w-full" />
        </div>
        <div>
          <img src={woprofile} alt="" className="mx-auto h-full w-full" />
        </div>
        <div className="flex flex-col gap-y-2 text-center text-sm">
          <span>정보 책임자 / 문의하기</span>
          <span>070-8983-1911</span>
        </div>
      </div>
    </>
  );
};
