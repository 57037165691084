import { Route, Routes } from 'react-router-dom';

import { KakaoRedirect } from './KakaoRedirect';
import { Login } from './Login';
import { Register } from './Register';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="login/kakao" element={<KakaoRedirect />} />
      <Route path="register" element={<Register />} />
      {/* <Route path="chat" element={<ChatRegister />} /> */}
    </Routes>
  );
};
