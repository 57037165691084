import * as React from 'react';

import { Head } from '../head';

type ContentLayoutProps = {
  children: React.ReactNode;
  title?: string;
};

export const ContentLayout = ({ children, title }: ContentLayoutProps) => {
  return (
    <div className="">
      <Head title={title} />
      <div className="h-full">
        <div className="border-b-2 py-2 text-center text-3xl font-bold">
          <h1>{title && title}</h1>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};
