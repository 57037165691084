import clsx from 'clsx';
import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import { Spinner } from '../spinner';

const variants = {
  primary: 'bg-blue-600 text-white',
  inactive: 'bg-inactive text-white',
  danger: 'bg-red-600 text-white',
  black: 'bg-black text-white',
};

const sizes = {
  sm: 'py-2 px-4 text-sm',
  md: 'py-3 px-6 text-md',
  lg: 'py-4 px-8 text-lg',
  bk: 'w-full py-3',
};

type IconProps = {
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
};
/* 
| { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined }; */

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean;
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  disabled?: boolean;
} & IconProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      isLoading = false,
      className = '',
      variant = 'primary',
      size = 'bk',
      startIcon,
      endIcon,
      disabled = false,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <button
          ref={ref}
          type={type}
          disabled={disabled}
          className={clsx(
            'flex items-center justify-center gap-1 rounded-[50px] border border-gray-300 font-medium shadow-sm hover:opacity-80 focus:outline-none disabled:cursor-not-allowed disabled:opacity-70',
            variants[variant],
            sizes[size],
            className
          )}
          {...props}
        >
          {isLoading && <Spinner size="sm" className="text-current" />}
          {!isLoading && startIcon}
          <span>{!isLoading ? props.children : 'Loading...'}</span>
          {!isLoading && endIcon}
        </button>
      </>
    );
  }
);

Button.displayName = 'Button';
