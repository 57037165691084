import * as React from 'react';

import kakaoLogin from '@/assets/images/kakaoLogin.png';
import { initChosenHospital, useChosenStore } from '@/stores/hospital';
import { history } from '@/utils/history';

export const KakaoLogin = () => {
  /* TODO: redirect uri 변수화 */
  const kauthUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_KEY}&redirect_uri=${process.env.REACT_APP_HOST}/auth/login/kakao&response_type=code&scope=profile_nickname,account_email,openid`;
  const { chooseHospital } = useChosenStore((state) => state);

  React.useEffect(() => {
    const listenBackEvent = () => {
      // 뒤로가기 할 때 수행할 동작을 적는다
      chooseHospital(initChosenHospital);
    };

    const unlistenHistoryEvent = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });

    return unlistenHistoryEvent;
  }, [
    chooseHospital, // effect에서 사용하는 state를 추가
  ]);

  return (
    <>
      <a href={kauthUrl}>
        <img
          src={kakaoLogin}
          id="kakao-login-btn"
          className="w-full"
          alt="kakao login"
        />
      </a>
    </>
  );
};
