import { Link } from '@/components/elements';

import { KakaoLogin } from '../components/Kakao';
import { Layout } from '../components/Layout';
export const Login = () => {
  return (
    <Layout title="로그인">
      {/* <LoginForm onSuccess={() => navigate('/')} /> */}
      {/* STYLE: kakao 로그인 버튼 디자인 대로 맞추기 */}
      <div className="flex justify-center py-4">
        <KakaoLogin />
      </div>
      <div className="flex justify-center">
        {/* TODO: link url 설정 */}
        <Link className="text-xs text-black" to={''}>
          로그인에 문제가 있습니까?
        </Link>
      </div>
    </Layout>
  );
};
