import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

// import { Spinner } from '@/components/elements/spinner';
import { MainLayout } from '@/components/layout/MainLayout';
// import { ChatRegister } from '@/features/auth/routes/ChatRegister';
import { ErrorFallback } from '@/providers/app';
import { lazyImport } from '@/utils/lazyimport';

const { ReviewRoutes } = lazyImport(
  () => import('@/features/review'),
  'ReviewRoutes'
);
const { Profile } = lazyImport(() => import('@/features/profile'), 'Profile');
const { ChatRegister } = lazyImport(
  () => import('@/features/profile'),
  'ChatRegister'
);

const { MapRoutes } = lazyImport(() => import('@/features/map'), 'MapRoutes');

const App = () => {
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <MainLayout>
        <Suspense
          fallback={
            <div className="flex h-full w-full items-center justify-center">
              {/* <Spinner size="xl" /> */}
              App 페이지 불러오는 중...
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </MainLayout>
    </ErrorBoundary>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: 'review/*', element: <ReviewRoutes /> },
      // { path: 'profile/*', element: <Profile /> },
      { path: ':hospitalId/profile', element: <Profile /> },
      { path: 'map/*', element: <MapRoutes /> },
      { path: 'chat/*', element: <ChatRegister /> },
    ],
  },
];
