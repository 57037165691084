import { initReactQueryAuth } from 'react-query-auth';

// import { Spinner } from '@/components/elements/spinner';
import {
  LoginCreadentialsDTO,
  RegisterCredentialsDTO,
  AuthUser,
  UserResponse,
  getUser,
  loginTest,
  registerTest,
} from '@/features/auth';
import storage from '@/utils/storage';

async function handleUserResponse(data: UserResponse) {
  const { jwt, user } = data;
  storage.setToken(jwt);
  return user;
}

async function loadUser() {
  if (storage.getToken()) {
    /* FIXME: 무한로딩 */
    const data = await getUser();

    return data;
  }
  return null;
}

async function loginFn() {
  const response = loginTest();
  const user = await handleUserResponse(response);
  return user;
}

// FIXME: localstorage 버그  있음.
async function registerFn(data: RegisterCredentialsDTO) {
  const response = registerTest(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        {/* <Spinner size="xl" /> */}
        유저 정보 불러오는 중...
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  unknown,
  LoginCreadentialsDTO,
  RegisterCredentialsDTO
>(authConfig);
