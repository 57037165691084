import { create } from 'zustand';

import { Hospital } from '@/features/map/types';

export type ChosenHospital = Hospital;

type ChosenHospitalStore = {
  chosenHospital: ChosenHospital;
  chooseHospital: (Hospital: Hospital) => void;
};

export const initChosenHospital: ChosenHospital = {
  id: 0,
  name: '',
  ykiho: '',
  address: '',
  postcode: '',
  categoryCode: 0,
  categoryName: '',
  callNumber: '',
  lat: 0,
  lng: 0,
  score_avg: 0.0,
};

export const useChosenStore = create<ChosenHospitalStore>((set) => ({
  chosenHospital: initChosenHospital,
  chooseHospital: (hospital: Hospital) => set({ chosenHospital: hospital }),
}));
