import { create } from 'zustand';

export type Category = 'dental' | 'oriental' | 'medical' | 'all';

export type FilterOption = {
  score: number;
  reviewExists: boolean;
  category: Category;
};
// export type FilterOption = FilterOption;

type FilterOptionStore = {
  filterOption: FilterOption;
  setFilterOption: (filterOption: FilterOption) => void;
};

export const useFilterOptionStore = create<FilterOptionStore>((set) => ({
  filterOption: { score: 0.0, category: 'all', reviewExists: false },
  setFilterOption: (option: FilterOption) => set({ filterOption: option }),
}));
