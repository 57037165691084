import { AxiosResponse } from 'axios';

import { axios } from '@/lib/axios';
import storage from '@/utils/storage';

import { UserResponse } from '../types';

export type LoginCreadentialsDTO = {
  verifyCode: string;
};

export const loginWithEmailAndVerifyCode = (
  data: LoginCreadentialsDTO
): Promise<UserResponse> => {
  return axios.post('/auth/login', data);
};

export const loginTest = (): UserResponse => {
  const response: UserResponse = {
    jwt: '1234',
    user: {
      id: 1,
      email: '1231@test.com',
      phone: '010-1234-5678',
      borders: [
        {
          id: 1,
          type: 'DH',
          code: '11111',
        },
      ],
      identified: false,
      role: 'USER',
      accountLocked: false,
      inActive: true,
    },
  };
  return response;
};

// async function handleUserResponse(data: UserResponse) {
//   const { jwt, user } = data;
//   storage.setToken(jwt);
//   return user;
// }

export const sendKakaoTokenToServer = async (token: string) => {
  try {
    const res: AxiosResponse & { jwt: string } = await axios.post(
      '/auth/kakao',
      {
        accessToken: token,
      }
    );
    console.log('kakao login backend api res: ', res);
    /* res.status == 200 || res.status == 201 */
    if (res.status == 200) {
      console.log('login success');
      storage.setToken(res.jwt);
    } else {
      window.alert('login failed');
    }
  } catch (error) {
    console.log(error);
  }
};
