export type colorName =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'disabled'
  | 'gray'
  | 'black';

type color = {
  c100: string;
  c200: string;
};

export const ColorSet: Record<colorName, color> = {
  primary: {
    c100: '#2766f5',
    c200: '#2766f5',
  },
  secondary: {
    c100: '#7EABF9',
    c200: '#7EABF9',
  },
  white: {
    c100: '#FFFFFF',
    c200: '#FFFFFF',
  },
  disabled: {
    c100: '#D0D0D0',
    c200: '#D0D0D0',
  },
  gray: {
    c100: '#F8F8F8',
    c200: '#F0F0F0',
  },
  black: {
    c100: '#000000',
    c200: '#000000',
  },
};
/* 
caution: '#F83860',
hover: '#2050BE',
disabled: '#D0D0D0',
success: '#00B556', */

/* 
gray: {
  gray100: '#F8F8F8',
  gary200: '#F0F0F0',
  gray300: '#E8E8E8',
  gray400: '#D0D0D0',
  gray500: '#BCBCBC',
  gray600: '#949494',
  gray700: '#787878',
  gray800: '#505050',
  gray900: '#282828',
  gray1000: '#101010',
}, */
