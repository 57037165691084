import { Combobox, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

// import { Icon } from '@/components/elements/icon';
import { Icon } from '@/components/elements/icon';
import { axios } from '@/lib/axios';
import { useChosenStore } from '@/stores/hospital';

import { Hospital } from '../types';

/* generator function which temporary optimizing autocompletion search by limiting counts of filtered items to maxsize. */
// function* filter(
//   array: Place[],
//   condition: (item: Place) => boolean,
//   maxSize: number
// ) {
//   if (!maxSize || maxSize > array.length) {
//     maxSize = array.length;
//   }
//   let count = 0;
//   let i = 0;
//   while (count < maxSize && i < array.length) {
//     if (condition(array[i])) {
//       yield array[i];
//       count++;
//     }
//     i++;
//   }
// }

type searchHospitalsApiDTO = {
  content: Hospital[];
};

const searchHospitalsAPI = (query: string): Promise<searchHospitalsApiDTO> => {
  return axios.get(`/hospitals/search?qs=${query}&page=1&size=10`);
};

const HospitalsSearchBarFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: any;
}) => (
  <div>
    <p> 에러: {error.message} </p>
    <button onClick={() => resetErrorBoundary()}> 다시 시도 </button>
  </div>
);

export const HospitalsSearchBar = () => {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<Hospital[]>([]);

  const { chosenHospital, chooseHospital } = useChosenStore((state) => state);

  const handleSelect = (item: Hospital) => {
    chooseHospital(item);
  };

  const handleSearch = useCallback(async (q: string) => {
    setLoading(true);

    const res = await searchHospitalsAPI(q);
    setLoading(false);
    setResults(res.content);
  }, []);

  useEffect(() => {
    if (query == '') return;
    // 200ms 지연 후 handleSearch 함수 실행
    const timeoutId = setTimeout(() => {
      handleSearch(query);
    }, 400);

    // 이전에 설정한 타임아웃이 있으면 해제
    return () => clearTimeout(timeoutId);
  }, [query, handleSearch]);

  return (
    <ErrorBoundary FallbackComponent={HospitalsSearchBarFallback}>
      <div className="w-full">
        <Combobox value={chosenHospital || ''} onChange={handleSelect}>
          <div className="relative mt-1">
            <div className="relative w-full cursor-default overflow-hidden py-1 text-left sm:text-sm">
              <Combobox.Input
                className="focus:text-ring-sky-500 w-full rounded-full border-2 border-black py-6 pl-3 pr-10 indent-8 text-lg leading-5 text-gray-900 shadow-md focus:border-primary focus:outline-none focus:ring-1 focus:placeholder:text-primary"
                displayValue={(hospital: Hospital) =>
                  hospital === undefined ? chosenHospital.name : hospital.name
                }
                onChange={(event: any) => {
                  return setQuery(event.target.value);
                }}
                onInput={(e: any) => {
                  setQuery(e.target.value);
                }}
                placeholder="병원 검색하기"
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center justify-center pr-6">
                {/* TODO: icon */}
                <Icon className="" icon="search" size={16} color="black" />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              {loading ? (
                <span>병원 불러오는중...</span>
              ) : (
                <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {results.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      검색 결과 없음.
                    </div>
                  ) : (
                    results.map((hospital) => (
                      <Combobox.Option
                        key={hospital.id}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? 'bg-teal-600 text-white' : 'text-gray-900'
                          }`
                        }
                        value={hospital}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center space-x-2">
                              <span
                                className={`${
                                  selected ? 'font-medium' : 'font-normal'
                                } text-sm`}
                              >
                                {hospital.name}
                              </span>
                              <span className="block truncate text-xs">
                                {hospital.address}
                              </span>
                            </div>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-white' : 'text-teal-600'
                                }`}
                              >
                                {/* TODO: check icon */}
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              )}
            </Transition>
          </div>
        </Combobox>
      </div>
    </ErrorBoundary>
  );
};
