// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useChosenStore } from '@/stores/hospital';

import { sendKakaoTokenToServer } from '../api/login';
// import { Layout } from '../components/Layout';

/* TODO: redirect uri productiuon 설정 */
export const KakaoRedirect = () => {
  const { chosenHospital } = useChosenStore((state) => state);
  const navigate = useNavigate();
  const query = queryString.parse(window.location.search);
  React.useEffect(() => {
    const getKakaoTokenHandler = async (code: string) => {
      const data: any = {
        grant_type: 'authorization_code',
        client_id: process.env.REACT_APP_KAKAO_REST_KEY,
        redirect_uri: `${process.env.REACT_APP_HOST}/auth/login/kakao`,
        code: code,
      };
      const queryString = Object.keys(data)
        .map(
          (k: any) => encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        )
        .join('&');

      //토큰 발급 REST API
      try {
        const res = await axios.post(
          'https://kauth.kakao.com/oauth/token',
          queryString,
          {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            },
          }
        );

        /* kakao 서버가 전달해준 access_token을 backend api로 요청 */
        await sendKakaoTokenToServer(res.data.access_token);
        /* backend에서 받은 result에서   */
        /* FIXME: landing page에서 선택한 병원이 있다면 카카오 로그인 완료후 map으로 바로 이동 */

        if (chosenHospital.id > 0) {
          navigate('/app/map');
        } else {
          navigate('/');
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (query.code) {
      getKakaoTokenHandler(query.code.toString());
    }
  }, [query.code, navigate, chosenHospital.id]);

  return (
    // <Layout title="카카오로그인">
    //   {/* TODO: loading spinner? */}
    //   <span>kakao 로그인 중입니다...</span>
    // </Layout>
    <span>로그인 중...</span>
  );
};
