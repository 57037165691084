import clsx from 'clsx';
import * as React from 'react';
import { FieldError } from 'react-hook-form';

const variants = {
  primary: '',
  inactive: 'bg-white text-blue-600',
  caution: '',
};

type FieldWrapperProps = {
  label?: string;
  className?: string;
  children: React.ReactNode;
  error?: FieldError | undefined;
  description?: string;
};

export type FieldWrapperPassThroughProps = Omit<
  FieldWrapperProps,
  'className' | 'children'
>;

export const FieldWrapper = (props: FieldWrapperProps) => {
  const { label, error, children, className } = props;
  return (
    <div className={className}>
      <label className={clsx('block text-sm')}>
        {label && label}
        <div
          className={clsx(
            `${error?.message ? variants.caution : variants.primary}`
          )}
        >
          {children}
        </div>
      </label>
      {error?.message && (
        <div role="alert" className={clsx('text-sm text-caution')}>
          {error.message}
        </div>
      )}
    </div>
  );
};
