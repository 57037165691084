import { axios } from '@/lib/axios';

import { UserResponse } from '../types';

export type RegisterCredentialsDTO = {
  email: string;
};

export const registerWithEmailAndVerifyCode = (
  data: RegisterCredentialsDTO
): Promise<UserResponse> => {
  return axios.post('/auth/register', data);
};

export const registerTest = (data: RegisterCredentialsDTO): UserResponse => {
  const response: UserResponse = {
    jwt: '1234',
    user: {
      id: 1,
      email: '1231@test.com',
      phone: '010-1234-5678',
      borders: [
        {
          id: 1,
          type: 'DH',
          code: '11111',
        },
      ],
      identified: false,
      role: 'USER',
      accountLocked: false,
      inActive: true,
    },
  };
  console.log(data);
  return response;
};
