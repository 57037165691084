import { MainLayout } from '@/components/layout';
import { Login } from '@/features/auth/routes/Login';
import { lazyImport } from '@/utils/lazyimport';

const { AuthRoutes } = lazyImport(
  () => import('@/features/auth'),
  'AuthRoutes'
);

// const { MiscRoutes } = lazyImport(
//   () => import('@/features/misc'),
//   'MiscRoutes'
// );

export const publicRoutes = [
  {
    path: '/*',
    element: (
      <MainLayout>
        {/* <MiscRoutes /> */}
        <Login />
      </MainLayout>
    ),
  },
  {
    path: '/auth/*',
    element: (
      <MainLayout>
        <AuthRoutes />
      </MainLayout>
    ),
  },
];
