import { RadioGroup } from '@headlessui/react';
import * as React from 'react';
// import { NavLink } from 'react-router-dom';

// import { useAuth } from '@/lib/auth';
import { useFilterOptionStore } from '@/stores/filter';

// import { Button, Link } from '../elements';
// import { Divider } from '../elements/divider';
// import { Icon } from '../elements/icon';
// import { MySwitch } from '../elements/switch';

// import { NavigationBar } from './NavigationBar';

// type SideNavigationItem = {
//   name: string;
//   to: string;
//   onClick?: () => void;
// };

// type SideNavigationProps = {
//   sidebarOpen: boolean;
//   setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
// };
// const SideNavigation = ({ setSidebarOpen }: SideNavigationProps) => {
//   const { user, logout } = useAuth();
//   const navigation: SideNavigationItem[] = [
//     //TODO: sidebar 관련 코드 정리 필요2 hello
//     {
//       name: '화이트리스트',
//       to: '/',
//       onClick: () => {
//         setSidebarOpen(false);
//       },
//     },
//     {
//       name: '리뷰하기',
//       to: user ? '/app/review' : '/auth/login',
//       onClick: () => {
//         setSidebarOpen(false);
//       },
//     },
//     {
//       name: '컨텐츠',
//       to: '/app/review',
//       onClick: () => {
//         setSidebarOpen(false);
//       },
//     },
//     {
//       name: '화이트리스트란?',
//       to: '',
//       onClick: () => {
//         setSidebarOpen(false);
//       },
//     },
//     {
//       name: 'Logout',
//       to: '',
//       onClick: () => {
//         logout();
//         setSidebarOpen(false);
//       },
//     },
//   ];

//   return (
//     <>
//       {navigation.map((item) => (
//         <NavLink
//           className="text-lg"
//           onClick={item.onClick}
//           key={item.name}
//           to={item.to}
//         >
//           {item.name}
//         </NavLink>
//       ))}
//     </>
//   );
// };

// type SidebarProps = {
//   sidebarOpen: boolean;
//   setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
// };

// const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
//   const { user } = useAuth();

//   const handleSidebar = () => {
//     setSidebarOpen(false);
//   };

//   return (
//     <Dialog
//       open={sidebarOpen}
//       onClose={setSidebarOpen}
//       className="relative z-[120]"
//     >
//       <div className="fixed inset-0 flex items-center justify-center">
//         <Dialog.Panel className="h-full w-full max-w-sm rounded bg-white">
//           <NavigationBar
//             left={
//               <button onClick={() => setSidebarOpen(false)}>
//                 <Icon icon="x" size={24} color="black" />
//               </button>
//             }
//             center="메뉴"
//           />
//           <div className="flex gap-x-4 border px-8 py-6">
//             <Icon
//               className="flex-shrink justify-start text-left align-middle"
//               icon="profile"
//               size={24}
//               color="black"
//             />
//             {user == undefined ? (
//               <span className="flex-grow text-left align-middle text-lg">
//                 어서오션!
//               </span>
//             ) : (
//               <span className="flex-grow">{user.email}</span>
//             )}
//             {user == undefined ? (
//               <Link
//                 className="algin-middle flex-grow justify-end text-right text-lg"
//                 onClick={handleSidebar}
//                 to="/auth/login"
//               >
//                 로그인하기
//               </Link>
//             ) : (
//               <span className="">내 정보 수정</span>
//             )}
//           </div>
//           <div className="flex h-2/4 flex-col gap-y-2 p-2">
//             <SideNavigation
//               sidebarOpen={sidebarOpen}
//               setSidebarOpen={setSidebarOpen}
//             />
//           </div>
//           <div className="flex flex-col gap-y-4 py-4 px-4">
//             {/* STYLE: divider 만들기 */}
//             <Divider />
//             <div className="flex gap-x-4 ">
//               <Icon
//                 className="flex-shrink align-middle"
//                 icon="consult"
//                 size={24}
//                 color="black"
//               />
//               <span className="align-middle text-base">01012341234</span>
//             </div>
//             <div className="flex gap-x-4">
//               <Icon
//                 className="flex-shrink align-middle"
//                 icon="mail"
//                 size={24}
//                 color="black"
//               />
//               <span className="align-middle text-base">
//                 theteamocean21@gmail.com
//               </span>
//             </div>
//             <div className="flex justify-evenly gap-x-4">
//               <Link className="text-base" onClick={handleSidebar} to="/">
//                 서비스이용약관
//               </Link>
//               <Link className="text-base" onClick={handleSidebar} to="/">
//                 개인정보보호약관
//               </Link>
//             </div>
//           </div>
//         </Dialog.Panel>
//       </div>
//     </Dialog>
//   );
// };

// type FilterProps = {
//   filterOpen: boolean;
//   setFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
// };

// const Filter = ({ filterOpen, setFilterOpen }: FilterProps) => {
//   // const [dutyDiv, setDutyDiv] = React.useState('all');
//   const { filterOption } = useFilterOptionStore();

//   const handleFilter = () => {
//     setFilterOpen(false);
//     console.log(filterOption);
//   };

//   return (
//     <Dialog
//       open={filterOpen}
//       onClose={setFilterOpen}
//       className="relative z-[120]"
//     >
//       <div className="fixed inset-0 flex items-center justify-center">
//         <Dialog.Panel className="h-full w-full max-w-sm rounded bg-white">
//           <NavigationBar
//             right={
//               <button onClick={() => setFilterOpen(false)}>
//                 <Icon icon="x" size={24} color="black" />
//               </button>
//             }
//             center="화이트리스트"
//           />
//           <div className="flex h-2/4 flex-col gap-y-2 p-2">
//             <div className="flex items-center justify-between">
//               <span>별점 3.0 이상 병원만 보기</span>
//               <MySwitch />
//             </div>
//             <Chips />
//             <Divider />
//             <Button onClick={handleFilter}>필터 적용하기</Button>
//           </div>
//         </Dialog.Panel>
//       </div>
//     </Dialog>
//   );
// };

export const Chips = () => {
  const { filterOption, setFilterOption } = useFilterOptionStore();

  const onCheck = (e: any) => {
    setFilterOption({ ...filterOption, category: e });
    // console.log(e);
  };

  return (
    <RadioGroup value={filterOption.category} onChange={onCheck}>
      <div className="flex justify-between">
        <RadioGroup.Option value="medical">
          {({ checked }) => (
            <span
              className={`${
                checked ? 'bg-primary text-white' : 'text-gray-400'
              } rounded-full border border-gray-400 px-2 py-1 text-sm hover:cursor-pointer`}
            >
              의과
            </span>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="dental">
          {({ checked }) => (
            <span
              className={`${
                checked ? 'bg-primary text-white' : 'text-gray-400'
              } rounded-full border border-gray-400 px-2 py-1 text-sm hover:cursor-pointer`}
            >
              치과
            </span>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="oriental">
          {({ checked }) => (
            <span
              className={`${
                checked ? 'bg-primary text-white' : 'text-gray-400'
              } rounded-full border border-gray-400 px-2 py-1 text-sm hover:cursor-pointer`}
            >
              한의과
            </span>
          )}
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  // const [sidebarOpen, setSidebarOpen] = React.useState(false);
  // const [filterOpen, setFilterOpen] = React.useState(false);
  return (
    <div className="flex h-screen justify-center overflow-hidden bg-white">
      <div
        id="main_layout"
        className="flex h-full max-w-sm flex-1 flex-col overflow-hidden border border-black bg-white"
      >
        {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Filter filterOpen={filterOpen} setFilterOpen={setFilterOpen} />
        <NavigationBar
          left={
            <button onClick={() => setSidebarOpen(true)}>
              <Icon icon="list" color="black" size={16} />
            </button>
          }
        /> */}
        <main className="flex-1 overflow-y-auto outline-none">{children}</main>
      </div>
    </div>
  );
};
