import { AuthUser } from '@/features/auth';

export const filterUserStatus = (user: AuthUser | undefined | null) => {
  let result = '';
  /* unauthenticated, unidentified, inactive  */
  if (!user) {
    result = 'unAuthenticated';
  }
  if (user) {
    if (user.identified == false) {
      result = 'unIdentified';
    }
    if (user.identified == true && user.inActive == true) {
      result = 'inActive';
    }
  }
  return result;
};
