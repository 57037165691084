import * as React from 'react';
import * as z from 'zod';

import { Button } from '@/components/elements/button';
import { Link } from '@/components/elements/link';
import { Form, InputField } from '@/components/form';

const schema = z.object({
  email: z
    .string()
    .email({ message: 'invalid email address' })
    .min(1, 'Required'),
  phone: z
    .string()
    .regex(new RegExp('^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$'), {
      message: 'invalid phone number template',
    })
    .min(1, 'Required'),
  verifyCode: z
    .string()
    .regex(new RegExp('[0-9]{6}'), { message: 'invalid code' })
    .min(6, 'min 6 characters'),
});

type RegisterValues = {
  email: string;
  phone: string;
  verifyCode: string;
};

type RegisterFormProps = {
  onSuccess: () => void;
};

export const RegisterForm = ({ onSuccess }: RegisterFormProps) => {
  return (
    <div>
      <Form<RegisterValues, typeof schema>
        onSubmit={async () => {
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <div className="flex items-center">
              <div className="flex-1">
                <InputField
                  type="text"
                  placeholder="핸드폰 번호"
                  error={formState.errors['phone']}
                  registration={register('phone')}
                />
              </div>
              <div className="flex-1">
                <Button>인증 하기</Button>
              </div>
            </div>
            <div>
              <InputField
                type="text"
                placeholder="인증번호"
                error={formState.errors['verifyCode']}
                registration={register('verifyCode')}
              />
              <InputField
                type="text"
                placeholder="이메일"
                error={formState.errors['email']}
                registration={register('email')}
              />
              <Button disabled={!formState.isValid} type="submit">
                register
              </Button>
            </div>
          </>
        )}
      </Form>
      <Link to="/auth/login">login</Link>
    </div>
  );
};
